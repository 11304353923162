<template>
  <div>
    <div>
      <main class="page-content">
        <div class="account-page-area">
          <div class="container containerDash">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                <sidebar-dashboard-customer></sidebar-dashboard-customer>
              </div>

              <!-- ///// -->
              <div class="col-lg-9">
                <h3>Refund History</h3>
                <div v-if="!customerList.length">
                  <notfound :checkdata="initialLoading"></notfound>
                </div>
                <!-- <div> -->
                <div v-else>
                  <b-table
                    :items="customerList"
                    :fields="field"
                   
                    responsive
                    stacked="sm"
                    
                  
                    disabled
                  >
                    
                  </b-table>
                 
                </div>
              </div>

              <!-- ///// -->
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import userService from "@/services/user.service";
import Config from "@/config";
export default {
  data() {
    return {
      paymentInfo: "",
      script: `https://checkout.razorpay.com/v1/checkout.js`,
      boxTwo: "",
      customerList: "",
      path: Config.BASE_URL_IMG,
      initialLoading: true,
      //  { key: 'value', label: 'value(R$)', sortable: true,
      //         formatter: (value, key, item) => value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
      //     },
      field: [
        { key: "phClaimHistoryId", label: "Id" },
        { key: "aucName", label: "Auction Name" },
        
        {
          key: "packAmount",
          label: "Pack Amount",
          formatter: (packAmount) =>
          packAmount.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            }),
        },
       {key:'tranactionDate',label:"Refunded Date"},
       {key:'tranactionId',label:"Transaction Id"},
       
       
        {
          key: "balanceAmount",
          label: "Refund Amount",
          formatter: (balanceAmount) =>
          balanceAmount.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            }),
        },
       {key:'isBalanceRefunded',label:"Refund Status"},
        
      ],
      isChecked: "",
      selected: [],
      aucIds: "",
      message: "",
      // sum_Of_Commission: null,
    };
  },
  mounted() {
    if (this.$store.state.auth.user) {
      this.st = this.$store.state.auth.user[0];

      this.getRefundHistory(this.st.customerId,0);
    } else {
      this.$router.push("/").catch(() => {
        "myaccount";
      });
    }
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
      // this.isChecked = true;
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
      this.selected = [];
      // this.isChecked = false;
    },
    // payCommission() {
    //   console.log(this.selected);

    // },

    makeToast(variant, body_content) {
      let tittleMessage = variant;
      if (variant == "danger") {
        tittleMessage = "Error";
      }
      if (variant == "success") {
        tittleMessage = "Success";
      }
      if (variant == "warning") {
        tittleMessage = "Warning";
      }

      this.$bvToast.toast(body_content, {
        title: `${tittleMessage || "default"}`,
        variant: variant,
        autoHideDelay: 5000,
        //solid: true,
        toaster: "b-toaster-bottom-right",
        appendToast: true,
      });
    },

   
    closeModal() {
      var self = this;

      setTimeout(function () {
        self.hideModal();
      }, 2000);
    },
    hideModal() {
      this.modelShow = false;
    },

    getRefundHistory(custId,isAdmin) {
      // console.log(sellerId);
      if (custId == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getRefundHistory(custId,isAdmin)
          .then((response) => {
            this.customerList = response.data.Data;
            this.initialLoading = false;
            //  this.params = response.data;
            // console.log(this.customerList);
          })
          .catch((e) => {
            console.log(e);
            this.initialLoading = false;
          });
      }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      if (
        !this.$store.state.auth.user[0].customerId == "" ||
        !this.$store.state.auth.user[0].customerId == " "
      ) {
        this.$router.push("/RefundHistory").catch(() => {
          "home";
        });
      } else {
        this.$router.push("/").catch(() => {
          "home";
        });
      }
    }
  },
  filters: {
    currency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      let country = Config.countryCode;
      let crncy = Config.curency;
      let FractionDigits = Config.minFractionDigits;
      const formatter = new Intl.NumberFormat(country, {
        style: "currency",
        currency: crncy,
        minimumFractionDigits: FractionDigits,
      });
      return formatter.format(value);
    },
  },
};
</script>
<style scoped>
th label {
  margin-bottom: 0px;
}
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}
table tr {
  background-color: #fff;
  border: 1px solid #e1e5e9;
  padding: 0.35em;
  border-radius: 3px;
}
table thead tr:first-child {
  border: 1px solid #0094da;
}
table th,
table td {
  padding: 8px;
  text-align: left;
  color: #202020;
  font-size: 14px;
}

table td:nth-child(4) {
  font-size: 18px;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background: #0094da;
  color: #fff;
}

table tbody tr td .btn-invoice {
  background: #0094da;
  color: #fff;
  font-size: 13px;
  padding: 10px 20px;
  border: 0;
  width: 100%;
  border-radius: 5px;
}

tbody tr:nth-child(even) {
  background-color: #eee;
}

tbody tr:nth-child(odd) {
  background-color: #fff;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }
  table caption {
    font-size: 1.3em;
  }
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
    padding: 0;
  }
  table tr {
    border-bottom: 3px solid #e1e5e9;
    display: block;
    margin-bottom: 0.625em;
  }
  table th,
  table td {
    padding: 0.625em;
  }
  table td {
    border-bottom: 1px solid #e1e5e9;
    display: block;
    font-size: 0.8em;
    text-align: right;
    color: #9da9b9;
  }
  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    color: #656971;
  }
  table td:last-child {
    border-bottom: 0;
  }
  table td:nth-child(4) {
    font-size: 0.8em;
  }
}
</style>
